import React, { useEffect, useState } from "react"
import { ZoomIn, ZoomOut, Play, Pause, RefreshCw, Hand } from "lucide-react"

export default function VirtualSampleViewer({ sample }) {
  const DEFAULT_FOV = 13 // ✅ Default zoom (Field of View)
  const DEFAULT_CAMERA_ORBIT = "0deg 75deg 1m" // ✅ Default camera position
  const DEFAULT_AUTO_ROTATE = true // ✅ Default auto-rotate state

  const MIN_FOV = 8 // ✅ Prevents excessive zoom-in
  const MAX_FOV = 20 // ✅ Prevents excessive zoom-out

  const [isAutoRotating, setIsAutoRotating] = useState(DEFAULT_AUTO_ROTATE)
  const [fieldOfView, setFieldOfView] = useState(DEFAULT_FOV)
  const [modelKey, setModelKey] = useState(0) // ✅ Forces re-mount on reset

  const [dimensions, setDimensions] = useState({
    minWidth: "400px",
    minHeight: "400px"
  })

  useEffect(() => {
    const updateDimensions = () => {
      if (window.innerWidth < 600) {
        setDimensions({ minWidth: "50px", minHeight: "50px" })
      } else {
        setDimensions({ minWidth: "400px", minHeight: "400px" })
      }
    }

    updateDimensions() // Initial check
    window.addEventListener("resize", updateDimensions)

    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  useEffect(() => {
    const modelViewer = document.getElementById("benchModel")

    const handleCameraChange = () => {
      if (modelViewer) {
        const fov = parseFloat(modelViewer.getAttribute("field-of-view"))
        const clampedFov = Math.max(MIN_FOV, Math.min(MAX_FOV, fov))

        if (fov !== fieldOfView) {
          setFieldOfView(clampedFov) // ✅ Sync state with scroll wheel zoom
        }
      }
    }

    if (modelViewer) {
      modelViewer.addEventListener("camera-change", handleCameraChange)
    }

    return () => {
      if (modelViewer) {
        modelViewer.removeEventListener("camera-change", handleCameraChange)
      }
    }
  }, [])

  useEffect(() => {
    const modelViewer = document.getElementById("benchModel")
    if (modelViewer) {
      modelViewer.setAttribute("field-of-view", `${fieldOfView}deg`)
    }
  }, [fieldOfView])

  useEffect(() => {
    const modelViewer = document.getElementById("benchModel")
    if (modelViewer) {
      if (isAutoRotating) {
        modelViewer.setAttribute("auto-rotate", "")
      } else {
        modelViewer.removeAttribute("auto-rotate")
      }
    }
  }, [isAutoRotating])

  if (!sample?.mediaItemUrl) {
    return <p>No model available</p>
  }

  const fileName = extractFileName(sample.mediaItemUrl)
  const localModelUrl = `${window.location.origin}/models/${fileName}`

  function extractFileName(url) {
    return url.split("/").pop()
  }

  const handlePlay = () => {
    setIsAutoRotating(true)
  }

  const handlePause = () => {
    setIsAutoRotating(false)
  }

  // ✅ Ensure zoom buttons and scroll wheel zoom work the same way
  const handleZoomIn = () => {
    setFieldOfView((prevFOV) => {
      const newFOV = Math.max(MIN_FOV, prevFOV - 5)
      return newFOV
    })
  }

  const handleZoomOut = () => {
    setFieldOfView((prevFOV) => {
      const newFOV = Math.min(MAX_FOV, prevFOV + 5)
      return newFOV
    })
  }

  // ✅ Fully reset EVERYTHING by forcing a re-mount
  const handleReset = () => {
    setModelKey((prevKey) => prevKey + 1) // ✅ Change key → Forces re-mount
    setFieldOfView(DEFAULT_FOV) // ✅ Reset zoom
    setIsAutoRotating(DEFAULT_AUTO_ROTATE) // ✅ Reset auto-rotate
  }

  return (
    <div>
      <div className="h-96">
        <model-viewer
          key={modelKey} // ✅ Forces full re-mount when changed
          id="benchModel"
          src={localModelUrl}
          camera-controls
          auto-rotate
          auto-rotate-delay="0"
          interaction-prompt="none"
          field-of-view={`${fieldOfView}deg`} // ✅ Dynamic FOV updates
          min-field-of-view={`${MIN_FOV}deg`} // ✅ Prevents excessive zoom-in
          max-field-of-view={`${MAX_FOV}deg`} // ✅ Prevents excessive zoom-out
          camera-orbit={DEFAULT_CAMERA_ORBIT} // ✅ Ensures correct reset
          alt="3D model"
          ar
          disable-zoom={true}
          style={{
            "--poster-color": "transparent",
            width: "100%",
            height: "100%",
            minWidth: dimensions.minWidth,
            minHeight: dimensions.minHeight
          }}
        ></model-viewer>
      </div>

      {/* ✅ Icon Controls */}
      <div className="flex justify-center gap-6 md:gap-10 mb-[35px] pt-[55px]">
        <ZoomIn className="w-6 h-6 md:w-8 md:h-8 cursor-pointer text-rc-grey hover:text-black" onClick={handleZoomIn} />
        <ZoomOut
          className="w-6 h-6 md:w-8 md:h-8 cursor-pointer text-rc-grey hover:text-black"
          onClick={handleZoomOut}
        />
        <Play className="w-6 h-6 md:w-8 md:h-8 cursor-pointer text-rc-grey hover:text-black" onClick={handlePlay} />
        <Pause className="w-6 h-6 md:w-8 md:h-8 cursor-pointer text-rc-grey hover:text-black" onClick={handlePause} />
        <RefreshCw
          className="w-6 h-6 md:w-8 md:h-8 cursor-pointer text-rc-grey hover:text-black"
          onClick={handleReset}
        />{" "}
        {/* ✅ Reset Button */}
      </div>
    </div>
  )
}
