import React, { useState, useEffect } from "react"
import { isIE } from "react-device-detect"

import Checkout from "../shop/Checkout"
import Order from "../shop/Order"

const Shop = ({ introduction, formId, cartFieldId, sections, uri }) => {
  const [isConfirmingOrder, setIsConfirmingOrder] = useState(false)
  const [cart, setCart] = useState([])

  useEffect(() => {
    isConfirmingOrder && !isIE && typeof window !== `undefined` && window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmingOrder])

  if (isConfirmingOrder) {
    return <Checkout {...{ formId, cartFieldId, setIsConfirmingOrder, cart, uri, setCart, sections }} />
  } else {
    return <Order {...{ introduction, cart, setCart, setIsConfirmingOrder, sections, uri }} />
  }
}

export default Shop
